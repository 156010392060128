<div class="devices-apps-items-wrapper" [formGroup]="form">
  <devices-apps-item
    *ngFor="let supplier of suppliers"
    (itemSelected)="selectItem($event, supplier.key)"
    [formControlName]="supplier.key"
    class="devices-apps-item"
    [iconSrc]="supplierIconService.getIconSourceForSupplier(supplier.key)"
    [title]="supplier.displayName"
    [key]="supplier.key"
    [isDisabled]="supplier.key === 'apple_healthkit' && !isIOS"
  ></devices-apps-item>
</div>
<div *ngIf="conflictingSuppliers">
  <p class="conflicting-suppliers-description">
    <span [innerHTML]="'SETTINGS.SOURCES.CONFLICTING_SUPPLIERS_PART1' | translate"></span>
    <span [innerHTML]="'SETTINGS.SOURCES.CONFLICTING_SUPPLIERS_CLICK_HERE' | translate" (click)="openMeasurementTypesModal()"></span>
    <span [innerHTML]="'SETTINGS.SOURCES.CONFLICTING_SUPPLIERS_PART2' | translate"></span>
  </p>
</div>
<measurement-type-source-modal>
</measurement-type-source-modal>
