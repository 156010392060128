import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeStringPipe } from './pipes/chat/time-string/time-string.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { FileSizeStringPipe } from './pipes/chat/file-size-string/file-size-string.pipe';
import { UnitPipe } from './pipes/unit.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BadgeHoverableComponent } from './components/badge-hoverable/badge-hoverable.component';
import { ButtonComponent } from './components/button/button.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ChartLegendComponent } from './components/charts/chart-legend/chart-legend.component';
import { ChartTooltipComponent } from './components/charts/chart-tooltip/chart-tooltip.component';
import { MeasurementChartComponent } from './components/charts/measurement-chart/measurement-chart.component';
// import { MeasurementComparativeSummaryComponent } from './components/charts/measurement-comparative-summary/measurement-comparative-summary.component';
import { TargetAchievementChartComponent } from './components/charts/target-achievement-chart/target-achievement-chart.component';
import { TeamPersonalProgressChartComponent } from './components/charts/team-personal-progress-chart/team-personal-progress-chart.component';
import { ChatInputFieldComponent } from './components/chat-input-field/chat-input-field.component';
import { FloatingMenuComponent } from './components/dashboard/floating-menu/floating-menu.component';
import { HamburgerMenuComponent } from './components/dashboard/hamburger-menu/hamburger-menu.component';
import { NavBottomMenuComponent } from './components/dashboard/nav-bottom-menu/nav-bottom-menu.component';
import { SetMeasurementsComponent } from './components/dashboard/set-measurements/set-measurements/set-measurements.component';
import { SetMeasurementsBlockComponent } from './components/dashboard/set-measurements/set-measurements-block/set-measurements-block.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { DurationInputFieldComponent } from './components/duration-input-field/duration-input-field.component';
import { EmojiListComponent } from './components/emoji-list/emoji-list.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ErrorComponent } from './components/error-component/error.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FullscreenOverlayComponent } from './components/fullscreen-overlay/fullscreen-overlay.component';
import { InfoBlockSmComponent } from './components/info-block-sm/info-block-sm.component';
import { InputBlockSelectorComponent } from './components/input-block-selector/input-block-selector.component';
import { InputPhoneComponent } from './components/input-phone/input-phone.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { InputSmsCodeComponent } from './components/input-sms-code/input-sms-code.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { InputTextFieldComponent } from './components/input-text-field/input-text-field.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { InvitationComponent } from './components/invitation/invitation.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { MeasurementCategoryBlocksComponent } from './components/measurement-category-blocks/measurement-category-blocks.component';
import { MeasurementDetailsComponent } from './components/measurement-details/measurement-details/measurement-details.component';
import { MeasurementSettingsComponent } from './components/measurement-settings/measurement-settings.component';
import { OnboardingCarouselBaseComponent } from './components/onboarding-carousel-base/onboarding-carousel-base.component';
import { PencilCloseButtonComponent } from './components/pencil-close-button/pencil-close-button.component';
import { PremiumExpirationPopupComponent } from './components/premium-expiration-popup/premium-expiration-popup.component';
import { PremiumPopupComponent } from './components/premium-popup/premium-popup.component';
import { PatientPortalSearchComponent } from './components/search-result/patient-portal-search.component';
import { SelectDropdownComponent } from './components/select-dropdown/select-dropdown.component';
import { SettingsOutlinedButtonComponent } from './components/settings-outlined-button/settings-outlined-button.component';
import { SkipButtonComponent } from './components/skip-button/skip-button.component';
import { SmallDropdownComponent } from './components/small-dropdown/small-dropdown.component';
import { TabbedContentComponent } from './components/tabbed-content/tabbed-content/tabbed-content.component';
import { TabbedContentFakeTabComponent } from './components/tabbed-content-fake-tab/tabbed-content-fake-tab.component';
import { ToggleCheckboxItemComponent } from './components/toggle-checkbox-item/toggle-checkbox-item.component';
import { UserProfileImageComponent } from './components/user-profile-image/user-profile-image.component';
import { WarningComponent } from './components/warning/warning.component';
import { WearableConnectionPopupComponent } from './components/wearable-connection-popup/wearable-connection-popup.component';
import { YesNoCheckboxComponent } from './components/yes-no-checkbox/yes-no-checkbox.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AggregateDataBlockComponent } from './components/measurement-details/aggregate-data-block/aggregate-data-block.component';
import { AddMeasurementDataPopupComponent } from './partials/dashboard/add-measurement-data-popup/add-measurement-data-popup.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ProgressBarSimpleComponent } from './components/dashboard/challenge-block/progress-bar-simple/progress-bar-simple.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { WearableVendorItemComponent } from './components/devices-apps-item/wearable-vendor-item.component';
import { NgChartsModule } from 'ng2-charts';
import { TabComponent } from './components/tabbed-content/tab/tab.component';
import { AddAudioPanelComponent } from './partials/dashboard/add-audio-panel/add-audio-panel.component';
import { AddNotePanelComponent } from './partials/dashboard/add-note-panel/add-note-panel.component';
import { AddImagePanelComponent } from './partials/dashboard/add-image-panel/add-image-panel.component';
import { RoomListComponent } from './partials/network/room-list/room-list.component';
import { ChatAvatarComponent } from './partials/network/chat-avatar/chat-avatar.component';
import { ChatPanelComponent } from './partials/network/chat-panel/chat-panel.component';
import { MessageListComponent } from './partials/network/message-list/message-list.component';
import { ChatMessageBubbleComponent } from './partials/network/chat-message-bubble/chat-message-bubble.component';
import { TestPageComponent } from '../modules/test-page/test-page.component';
import { ProfilePictureComponent } from './components/file-uploader/profile-picture/profile-picture.component';
import { ConnectionLostPopupComponent } from '../modules/connection-lost-popup/connection-lost-popup.component';
import { AddConnectionPopupComponent } from './partials/network/add-connection-popup/add-connection-popup.component';
import { ConnectionPermissionsPopupComponent } from './partials/network/connection-permissions-popup/connection-permissions-popup.component';
import { AddMeasurementPermissionsModalComponent } from './partials/network/add-measurement-permissions-modal/add-measurement-permissions-modal.component';
import { RemoveConnectionPopupComponent } from './partials/network/remove-connection-popup/remove-connection-popup.component';
import { MeasurementDetailsWideScreenComponent } from './components/measurement-details/measurement-details-wide-screen/measurement-details-wide-screen.component';
import { DashboardOnboardingWearablesAppsComponent } from '../modules/dashboard/dashboard-onboarding-wearables-apps/dashboard-onboarding-wearables-apps.component';
import { NotFoundComponent } from '../modules/not-found/not-found.component';
import { SsoErrorComponent } from '../modules/sso-error/sso-error.component';
import { NgxLongPress2Module } from 'ngx-long-press2';
import { BlueDotComponent } from './components/blue-dot/blue-dot.component';
import { PaymentDropinComponent } from './components/payment-dropin/payment-dropin.component';
import { ClipboardModule } from 'ngx-clipboard';
import { UnlockSliderComponent } from './components/unlock-slider/unlock-slider.component';
import { CardComponent } from './components/card/card.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { TableComponent } from './components/table-component/table.component';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import { AdminProfileButtonComponent } from './components/admin-profile-button/admin-profile-button.component';
import { OptionsButtonComponent } from './components/options-button/options-button.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BaseComponent } from '../modules/dashboard/base.component';
import { KnowledgeBaseComponent } from '../modules/dashboard/knowledge-base/knowledge-base.component';
import { WearablesAppsComponent } from '../modules/settings/wearables-apps/wearables-apps.component';
import {
  DisconnectPopupComponent
} from '../modules/settings/wearables-apps/disconnect-popup/disconnect-popup.component';
import { DevicesAppsComponent } from '../modules/settings/wearables-apps/devices-apps/devices-apps.component';
import { RouterModule } from '@angular/router';
import { ObserversModule } from '@angular/cdk/observers';
import { GreetingComponent } from './components/greeting/greeting.component';
import { MeasurementBlockComponent } from './components/dashboard/measurement-block/measurement-block.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TableHeaderComponent } from './components/table-component/table-header/table-header.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { EnumKeyValuePipe } from "./pipes/enum-key-value.pipe";
import { MeasurementTypeSourceModalComponent } from "./components/modals/measurement-type-source-modal/measurement-type-source-modal.component";
import {NumberFormatPipe} from "./pipes/number-format.pipe";
import {NgxBarcode6Module} from "ngx-barcode6";
import {NoAccessComponent} from "../modules/no-access/no-access.component";
import {NgSelectModule} from "@ng-select/ng-select";
import { TwoFactorErrorComponent } from '../modules/twofactor-error/twofactor-error.component';
import { SanitizeUrlPipe} from "./pipes/sanitize.pipe";
import { ConsentUpdateModalComponent } from './components/modals/consent-update-modal/consent-update-modal.component';
import {SharedContactDataComponent} from "../modules/dashboard/shared-contact-data/shared-contact-data.component";
import { TokenExpiredModalComponent } from './components/modals/token-expired-modal/token-expired-modal.component';
import { InlineSVGModule } from "ng-inline-svg-2";
import { MedmijTokenExpiredModalComponent } from './components/modals/medmij-token-expired-modal/medmij-token-expired-modal.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { WarningBoxComponent } from './components/warning-box/warning-box.component';
import { ExerciseMinutesBlockComponent } from './components/dashboard/exercise-minutes-block/exercise-minutes-block.component';

export const CHART_COMPONENTS = [
  ChartLegendComponent,
  ChartTooltipComponent,
  MeasurementChartComponent,
  // MeasurementComparativeSummaryComponent,
  TargetAchievementChartComponent,
  TeamPersonalProgressChartComponent,
];

export const WEARABLES_APPS_COMPONENT = [
  WearablesAppsComponent,
  DisconnectPopupComponent,
  DevicesAppsComponent,
  WearableConnectionPopupComponent,
];


export const COMPONENTS = [
  AccordionComponent,
  AggregateDataBlockComponent,
  AddMeasurementDataPopupComponent,
  BackButtonComponent,
  BadgeHoverableComponent,
  ButtonComponent,
  CalendarComponent,
  CarouselComponent,
  ...CHART_COMPONENTS,
  ChatInputFieldComponent,
  CheckboxComponent,
  DateTimePickerComponent,
  DurationInputFieldComponent,
  EmojiListComponent,
  EmptyStateComponent,
  ErrorComponent,
  FileUploaderComponent,
  FullscreenOverlayComponent,
  InfoBlockSmComponent,
  InputBlockSelectorComponent,
  InputPhoneComponent,
  InputSearchComponent,
  InputSmsCodeComponent,
  InputTextComponent,
  InputTextFieldComponent,
  InvitationComponent,
  LoadingModalComponent,
  MeasurementBlockComponent,
  MeasurementCategoryBlocksComponent,
  MeasurementDetailsComponent,
  MeasurementSettingsComponent,
  OnboardingCarouselBaseComponent,
  PencilCloseButtonComponent,
  PremiumExpirationPopupComponent,
  PremiumPopupComponent,
  PatientPortalSearchComponent,
  SelectDropdownComponent,
  SettingsOutlinedButtonComponent,
  SkipButtonComponent,
  SmallDropdownComponent,
  TabbedContentComponent,
  TabbedContentFakeTabComponent,
  ToggleCheckboxItemComponent,
  UserProfileImageComponent,
  WarningComponent,
  YesNoCheckboxComponent,
  ProgressBarSimpleComponent,
  WearableVendorItemComponent,
  TabComponent,
  AddAudioPanelComponent,
  AddNotePanelComponent,
  AddImagePanelComponent,
  // partials
  RoomListComponent,
  ChatAvatarComponent,
  ChatPanelComponent,
  MessageListComponent,
  ChatMessageBubbleComponent,
  TestPageComponent,
  ProfilePictureComponent,
  ConnectionLostPopupComponent,
  AddConnectionPopupComponent,
  ConnectionPermissionsPopupComponent,
  AddMeasurementPermissionsModalComponent,
  RemoveConnectionPopupComponent,
  MeasurementDetailsWideScreenComponent,
  DashboardOnboardingWearablesAppsComponent,
  NotFoundComponent,
  SsoErrorComponent,
  NoAccessComponent,
  BlueDotComponent,
  PaymentDropinComponent,
  UnlockSliderComponent,
  BlueDotComponent,
  PaymentDropinComponent,
  UnlockSliderComponent,
  CardComponent,
  TableComponent,
  AdminHeaderComponent,
  AdminProfileButtonComponent,
  OptionsButtonComponent,
  DropdownMenuComponent,
  NavBottomMenuComponent,
  HamburgerMenuComponent,
  FloatingMenuComponent,
  BaseComponent,
  SetMeasurementsComponent,
  SetMeasurementsBlockComponent,
  KnowledgeBaseComponent,
  WEARABLES_APPS_COMPONENT,
  MeasurementTypeSourceModalComponent,
  ConsentUpdateModalComponent,
  TwoFactorErrorComponent,
  SharedContactDataComponent,
  ConfirmPopupComponent,
  GreetingComponent,
  TableHeaderComponent,
  TokenExpiredModalComponent
]

export const PIPES = [
  TimeStringPipe,
  DateTimeFormatPipe,
  FileSizeStringPipe,
  UnitPipe,
  EnumKeyValuePipe,
  NumberFormatPipe,
  SanitizeUrlPipe
];

@NgModule({
    declarations: [
        ...PIPES,
        ...COMPONENTS,
        ConfirmPopupComponent,
        GreetingComponent,
        TableHeaderComponent,
        MedmijTokenExpiredModalComponent,
        ConfirmModalComponent,
        WarningBoxComponent,
        ExerciseMinutesBlockComponent
    ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    InlineSVGModule,
    NgChartsModule,
    DpDatePickerModule,
    FullCalendarModule,
    NgxLongPress2Module,
    ClipboardModule,
    NgxIntlTelInputModule,
    DragDropModule,
    HighchartsChartModule,
    RouterModule,
    ObserversModule,
    NgxPaginationModule,
    NgxBarcode6Module,
    NgSelectModule
  ],
  exports: [
    ...PIPES,
    ...COMPONENTS,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxSmartModalModule,
    InlineSVGModule,
    NgChartsModule,
    DpDatePickerModule,
    FullCalendarModule,
    NgxLongPress2Module,
    ClipboardModule,
    CardComponent,
    HighchartsChartModule,
    TableComponent,
    NgxIntlTelInputModule,
    DragDropModule,
    RouterModule,
    ObserversModule,
    NgxPaginationModule,
    NgxBarcode6Module,
    ConfirmPopupComponent,
    GreetingComponent,
    TableHeaderComponent,
    NgSelectModule,
    MedmijTokenExpiredModalComponent,
    ConfirmModalComponent,
    WarningBoxComponent,
    ExerciseMinutesBlockComponent
  ]
})
export class SharedModule { }
