import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList, SimpleChanges,
  ViewChild,
} from '@angular/core';
import {TabComponent} from '../tab/tab.component';
import {Router} from '@angular/router';
import {Subscription} from "rxjs";

@Component({
  selector: 'tabbed-content',
  templateUrl: './tabbed-content.component.html',
  styleUrls: ['./tabbed-content.component.scss'],
})
export class TabbedContentComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit, OnChanges {
  @ViewChild('tabbedContentNav') tabbedContentNav: ElementRef;
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  // whether to input to parent component the moment a tab has changed (not always needed, so optional)
  @Input() includeEventEmitterOnChangeTab = false;
  @Input() hasTabChildren = false;
  @Input() defaultTabName = '';

  @Output() tabChanged = new EventEmitter();

  previousActiveTab: TabComponent;

  private routerEventsSubscription: Subscription;

  @Input() waitForData = false;
  @Input() dataLoaded = false;

  constructor(
    readonly router: Router
  ) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes?.dataLoaded?.currentValue === true) {
      this.routerEventsSubscription = this.router.events.subscribe(this.checkForActiveTabs.bind(this));
      await this.checkForActiveTabs();
    }
  }

  ngOnInit(): void {
    if(!this.waitForData) {
      this.routerEventsSubscription = this.router.events.subscribe(this.checkForActiveTabs.bind(this));
    }
  }

  ngOnDestroy() {
    this.routerEventsSubscription.unsubscribe();
  }

  async ngAfterContentInit(): Promise<void> {
    if(!this.waitForData) {
      await this.checkForActiveTabs();
    }
  }

  ngAfterViewInit(): void {
    this.scrollActiveTabIntoView();
  }

  async checkForActiveTabs(): Promise<void> {
    if(!this.tabs) {
      return ;
    }

    let tabByRouterUrl = this.tabs.find(t => t.routerLinkUrl === this.router.url);
    const activeTabs = this.tabs.filter(tab => tab.isActive);

    // select the first tab if none is active
    if (activeTabs.length < 1) {
      const visibleTabs: TabComponent[] = [];
      this.tabs.forEach(tab => tab.hideTab ? null : visibleTabs.push(tab));

      if(tabByRouterUrl) {
        this.selectTab(tabByRouterUrl);
      }
      else if(this.defaultTabName !== '') {
        this.selectTab(visibleTabs.find(vTab => vTab.name === this.defaultTabName));
      }
      else {
        this.selectTab(visibleTabs[0]);
      }
    }
  }

  selectTab(tab: TabComponent): void {
    if(tab === undefined){
      return;
    }
    this.previousActiveTab = this.tabs.find(tab => tab.isActive);
    const isAlreadyActiveTab = this.tabs.find(tab => tab.isActive) === tab;
    this.tabs.forEach(tab => tab.isActive = false);
    tab.isActive = true;

    if (isAlreadyActiveTab) window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (tab.routerLinkUrl) {
      this.router.navigateByUrl(tab.routerLinkUrl)
        .then(() => {
          if (this.includeEventEmitterOnChangeTab) {
            this.tabChanged.emit(tab);
          }
          this.scrollActiveTabIntoView();
        });
    } else {
      if (this.includeEventEmitterOnChangeTab) {
        this.tabChanged.emit(tab);
      }
      this.scrollActiveTabIntoView();
    }
  }

  scrollActiveTabIntoView(scrollBehaviorSmooth = true): void {
    if (this.tabbedContentNav) {
      setTimeout(() => {
        const ifSelectedTab = this.tabbedContentNav.nativeElement.querySelector('.tab.active');
        if (ifSelectedTab) ifSelectedTab.scrollIntoView({
          inline: 'center',
          block: 'center',
          behavior: scrollBehaviorSmooth ? 'smooth' : 'auto'
        });
      });
    }
  }
}
