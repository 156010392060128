import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BUTTON_STYLES } from '../button/button.component';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import { take } from 'rxjs/operators';

@Component({
  selector: 'premium-popup',
  templateUrl: './premium-popup.component.html',
  styleUrls: ['./premium-popup.component.scss']
})
export class PremiumPopupComponent implements OnInit {
  modalId = 'premiumModal';
  BUTTON_STYLES = BUTTON_STYLES;
  MONTH_PRICE = 2.99;
  YEAR_PRICE = 29.99;

  MONTH_PRICE_STRING = ` (€${this.MONTH_PRICE})`;
  YEAR_PRICE_STRING = ` (€${this.YEAR_PRICE})`;

  premiumCheckpoints: string[] = [];

  constructor(
    public modal: NgxSmartModalService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.loadCheckpointTranslations();
  }

  ngOnInit(): void {
  }

  public open() {
    this.modal.open(this.modalId);
  }

  goToPayment() {
    this.modal.close(this.modalId);

    if ((window as any).NativeApp) {
      (window as any).NativeApp.postMessage(JSON.stringify({
        action: 'start-purchase',
        iap_id: 'selfcare_premium_2022'
      }));
    } else if ((window as any).webkit && (window as any).webkit.messageHandlers.NativeApp) {
      (window as any).webkit.messageHandlers.NativeApp.postMessage({
        action: 'start-purchase',
        iap_id: 'selfcare_premium_2022'
      });
    } else {
      this.router.navigate(['dashboard/settings/financial'], {queryParams: {premiumForward: true}});
    }
  }

  get nativeAppAvailable() {
    if ((window as any).webkit && !(window as any).webkit.messageHandlers.NativeApp) {
      (window as any).webkit.messageHandlers.NativeApp = (window as any).NativeApp;
    }
    return (window as any).webkit && (window as any).webkit.messageHandlers.NativeApp;
  }

  loadCheckpointTranslations() {
    this.translate.get("PREMIUM_POPUP.CHECK_POINTS")
      .pipe(take(1))
      .subscribe(translation => {
        this.premiumCheckpoints = Object.keys(translation).map((_: any, i: number) => this.translate.instant(`PREMIUM_POPUP.CHECK_POINTS.[${i}]`))
      })
  }
}
