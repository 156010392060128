import {Injectable} from '@angular/core';
import {
  MEASUREMENT_CATEGORIES,
  MEASUREMENT_TYPES,
  MeasurementCategory,
  MeasurementIdEnum,
  MeasurementLimitIndication,
  MeasurementProgressionInterpretation,
  MeasurementQualityType,
} from '../models/enums/MeasurementEnums';
import {MeasurementType} from '../models/MeasurementType';
import {TranslateService} from '@ngx-translate/core';
import {DateService} from '../utils/date.service';
import {NumberService} from '../utils/number.service';
import {ApiService} from '../api/api.service';
import {DashboardTile} from '../models/dashboard/DashboardResponse';
import {OidcService} from 'src/app/oidc/oidc.service';
import MeasurementTile from '../models/enums/MeasurementTile';
import {MeasurementTypeSelectionResponse} from '../models/dashboard/MeasurementTypeSelectionGroup';
import {
  IndicatorType
} from "../../modules/atalmedial/test-results/line-indicator/line-indicator.component";
import {Store} from "@ngrx/store";
import UserProfile from "../models/userProfile";
import {Subscription} from "rxjs";

/** COLOR CONSTANTS */
const COLOR_GREEN = '#0B9645'; // white text
const COLOR_LIGHT_GREEN = '#1CC413'; //white text
const COLOR_OLIVE = '#A9B207'; //black text
const COLOR_ORANGE = '#F4B710'; //black text
const COLOR_RED = '#F56E80'; //black text

/** COLOR SHADE CONSTANTS, darker variants of the base colors */
const COLOR_GREEN_SHADE = '#064f24';
const COLOR_LIGHT_GREEN_SHADE = '#127e0c';
const COLOR_OLIVE_SHADE = '#636804';
const COLOR_ORANGE_SHADE = '#af8308';
const COLOR_RED_SHADE = '#f02641';

interface MeasurementCategoryObj {
  key: MeasurementCategory;
  isMeasurementType: boolean;
  order: number;
  tiles: DashboardTile[];
}

@Injectable({
  providedIn: 'root'
})
export class MeasurementService {
  customMeasurementTypes: MeasurementType[] = [];
  private readonly sub: Subscription;
  private cultureCode: string;

  constructor(
    private translate: TranslateService,
    private oidcService: OidcService,
    public dateService: DateService,
    public numberService: NumberService,
    public apiService: ApiService,
    private store: Store<{ 'userProfile': UserProfile }>
  ) {
    this.sub = this.store.subscribe(userProfile => {
      if(userProfile && userProfile.userProfile) {
        this.cultureCode = userProfile.userProfile.cultureCode;
      }
    });
  }

  /**
   * Returns the translated title of a measurement type
   * @param {string} measurementTypeId
   * @param customId
   * @returns {string}
   */
  getMeasurementTitleString(measurementTypeId: string): string {
    return this.translate.instant(`MEASUREMENT_DETAILS.MEASUREMENTS.${measurementTypeId}.TITLE`);
  }

  getCustomMeasurementTitleString(title: string): string {
    return title;
  }

  // Format numbers based on browser lang
  /**
   * Formats a measurement value number and returns as nice string.
   * @param {string} measurementTypeId
   * @param {string | number} value
   * @returns {string}
   */
  formatMeasurementValue(measurementTypeId: string, value: string | number): string {
    const measurementType: MeasurementType = measurementTypeId?.startsWith(MeasurementIdEnum.CUSTOM) ? MEASUREMENT_TYPES[MeasurementIdEnum.CUSTOM] : MEASUREMENT_TYPES[measurementTypeId];

    if (value === null) return '0';
    // todo certain measurements types could have specific formatting requirements. None have been added (yet)

    if (measurementType?.formatValueStringFunction) {
      return measurementType.formatValueStringFunction(value);
    }

    if (typeof value !== 'number') {
      value = parseFloat(value);
    }

    switch(measurementType) {
      case MEASUREMENT_TYPES['CHOLESTEROL']:
      case MEASUREMENT_TYPES['CHOLESTEROL_B']:
      case MEASUREMENT_TYPES['CHOLESTEROL_NL']:
      {
        switch (this.oidcService.culture){
          case 'nl-be':
            value = value / 0.0555;
            break;
        }
        break;
      }
    }

    return this.numberService.formatNumber(value, measurementType?.roundValueToDecimals);
  }

  /**
   * Get the unit of a measurement type, if it has one
   * @param {string} measurementTypeId
   * @returns {string}
   */
  getUnitString(measurementTypeId: string): string {
    const measurementType: MeasurementType = MEASUREMENT_TYPES[measurementTypeId];
    if (!measurementType?.unit) return '';

    switch(measurementType) {
      case MEASUREMENT_TYPES['CHOLESTEROL']:
      case MEASUREMENT_TYPES['CHOLESTEROL_B']:
      case MEASUREMENT_TYPES['BLOODSUGAR']:
      case MEASUREMENT_TYPES['CHOLESTEROL_NL']:
      {
        switch (this.cultureCode){
          case 'nl-nl':
            measurementType.unit = 'mmol/l';
            break;
          case 'nl-be':
            measurementType.unit = 'mg/dl';
            break;
        }
        break;
      }
    }

    return measurementType.unit;
  }

  getCustomUnitString(measurementTileModel: MeasurementTile) {
    return measurementTileModel.unit;
  }

  /**
   * Returns the SVG icon related to a measurement type
   * @param {string} measurementTypeId
   * @returns {string}
   */
  // todo use enum
  getMeasurementIcon(measurementTypeId: string): string {
    if (!measurementTypeId) return 'measurements/icn_selfcare.svg';
    if (measurementTypeId.startsWith(MeasurementIdEnum.CUSTOM)) return 'measurements/icn_custom_measurement.svg';
    // console.log('get test');
    const measurementType: MeasurementType = MEASUREMENT_TYPES[measurementTypeId];
    if (!measurementType) return 'measurements/icn_selfcare.svg';
    return 'measurements/' + measurementType.icon;
  }

  /**
   * Gets the 'interpretation' string based on the given MeasurementProgressionInterpretation
   * @param {MeasurementProgressionInterpretation} interpretation
   * @returns {string}
   */
  getProgressionInterpretationString(interpretation: MeasurementProgressionInterpretation | null): string {
    // console.log('getProgressionInterpretationString interpretation', interpretation);
    if (!interpretation) return this.translate.instant('MEASUREMENT_DETAILS.QUALITY_INTERPRETATION.NO_MEASUREMENT_YET');
    return this.translate.instant(`MEASUREMENT_DETAILS.QUALITY_INTERPRETATION.${interpretation}`);
  }

  /**
   * Get the color associated with certain 'limitIndications' (qualities).
   * Used for coloring strings, data points, bars of barchart
   * @param {string} measurementTypeId
   * @param {string} quality
   * @returns {string}
   */
  getQualityColor(measurementTypeId: string, quality: MeasurementLimitIndication): string {
    if (quality === MeasurementLimitIndication.BEST) return COLOR_GREEN;
    if (quality === MeasurementLimitIndication.BETTER) return COLOR_LIGHT_GREEN;
    if (quality === MeasurementLimitIndication.MIDDLE) {
      const measurementType: MeasurementType = measurementTypeId.startsWith(MeasurementIdEnum.CUSTOM) ? MEASUREMENT_TYPES[MeasurementIdEnum.CUSTOM] : MEASUREMENT_TYPES[measurementTypeId];
      return measurementType.qualityType === MeasurementQualityType.MIDDLE_IS_BETTER ? COLOR_LIGHT_GREEN : COLOR_OLIVE;
    }
    if (quality === MeasurementLimitIndication.WORSE) return COLOR_ORANGE;
    if (quality === MeasurementLimitIndication.WORST) return COLOR_RED;
    return '#000000';
  }

  /**
   * Get the measurement quality type of a measurement type:
   * HIGHER_IS_BETTER, MIDDLE_IS_BETTER or LOWER_IS_BETTER
   * @param {string} measurementTypeId
   * @returns {MeasurementQualityType}
   */
  getMeasurementQualityType(measurementTypeId: string): MeasurementQualityType {
    const measurementType: MeasurementType = MEASUREMENT_TYPES[measurementTypeId];
    return measurementType.qualityType;
  }

  getTimePeriodTranslatedDisplayFormatStrings() {
    return {
      Day: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_HOURS').toUpperCase(),
      // Week: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_DAYS').toUpperCase(),
      Week: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_WEEKDAYS').toUpperCase(),
      Month: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_DAYS').toUpperCase(),
      CalendarMonth: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_DAYS').toUpperCase(),
      // Quarter: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_WEEKS').toUpperCase(),
      Trimester: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_WEEKS').toUpperCase(),
      Year: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_MONTHS').toUpperCase(),
      All: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_MONTHS').toUpperCase(),
      // All: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_QUARTERS').toUpperCase(),
    };
  }

  getQualityColorShadeByOriginalColor(color: string): string {
    if (color === COLOR_GREEN) return COLOR_GREEN_SHADE;
    else if (color === COLOR_LIGHT_GREEN) return COLOR_LIGHT_GREEN_SHADE;
    else if (color === COLOR_OLIVE) return COLOR_OLIVE_SHADE;
    else if (color === COLOR_ORANGE) return COLOR_ORANGE_SHADE;
    return COLOR_RED_SHADE;
  }

  getEmptyMeasurementSectionSet(): MeasurementCategoryObj[] {
    const measurementCategories = [...MEASUREMENT_CATEGORIES];

    const emptyMeasurementSections = [];

    for (let i = 0; i < measurementCategories.length; i++) {
      const measurementCategory = measurementCategories[i];
      const measurementCategoryObj: MeasurementCategoryObj = {
        key: measurementCategory,
        isMeasurementType: true,
        order: i,
        tiles: [],
      };

      emptyMeasurementSections.push(measurementCategoryObj);
    }

    return emptyMeasurementSections;
  }

  getMeasurementsGroupedByCategory(measurementCategory: MeasurementCategory): MeasurementType[] {
    const allMeasurementTypes = Object.values(MEASUREMENT_TYPES).concat(this.customMeasurementTypes);
    return allMeasurementTypes.filter(type => type.measurementCategory === measurementCategory);
  }

  getMeasurementTypeById(measurementId: string, customName?: string): MeasurementType {
    const allMeasurementTypes = Object.values(MEASUREMENT_TYPES).concat(this.customMeasurementTypes);
    return allMeasurementTypes.find((measurementType: MeasurementType) => measurementType.id === measurementId);
  }

  getGradientLineColorsBasedOnMeasurementType(measurementTypeId: string) {
    if(measurementTypeId.startsWith(MeasurementIdEnum.CUSTOM)) measurementTypeId = MeasurementIdEnum.CUSTOM;
    const measurementType: MeasurementType = MEASUREMENT_TYPES[measurementTypeId];
    // --measurement_color_green: #0B9645;
    // --measurement_color_light_green: #1CC413;
    // --measurement_color_olive: #A9B207;
    // --measurement_color_orange: #F4B710;
    // --measurement_color_red: #F56E80;
    if (measurementType?.qualityType === MeasurementQualityType.LOWER_IS_BETTER) {
      return [
        {
          color: '#F56E80',
          // colorHumanString: 'red',
        },
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
        {
          color: '#A9B207',
          // colorHumanString: 'olive',
        },
        {
          color: '#1CC413',
          // colorHumanString: 'light green',
        },
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
      ];
    }
    if (measurementType?.qualityType === MeasurementQualityType.MIDDLE_IS_BETTER) {
      return [
        {
          color: '#F56E80',
          // colorHumanString: 'red',
        },
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
        {
          color: '#1CC413',
          // colorHumanString: 'light green',
        },
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
        {
          color: '#F56E80',
          // colorHumanString: 'red',
        },
      ];
    }
    if (measurementType?.qualityType === MeasurementQualityType.HIGHER_IS_BETTER) {
      return [
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
        {
          color: '#1CC413',
          // colorHumanString: 'light green',
        },
        {
          color: '#A9B207',
          // colorHumanString: 'olive',
        },
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
        {
          color: '#F56E80',
          // colorHumanString: 'red',
        },
      ];
    }
    return [];
  }

  /** Todo deprecate soon when API works */
  getQualityLegendData(measurementTypeId: string | undefined): [] | [{ colorClass: string; label: string }, { colorClass: string; label: string }, { colorClass: string; label: string }, { colorClass: string; label: string }, { colorClass: string; label: string }] {
    //set measurementTypeId to CUSTOM to match hardcoded FE
    if(measurementTypeId.startsWith(MeasurementIdEnum.CUSTOM)) measurementTypeId = MeasurementIdEnum.CUSTOM;

    if (!measurementTypeId) { return []; }
    const measurementType: MeasurementType = MEASUREMENT_TYPES[measurementTypeId];
    if (measurementType?.qualityType === MeasurementQualityType.LOWER_IS_BETTER) {
      return [
        {
          colorClass: 'red',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.TOO_HIGH'),
        },
        {
          colorClass: 'orange',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.HIGH'),
        },
        {
          colorClass: 'olive',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.TARGET_VALUE'),
        },
        {
          colorClass: 'light-green',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.BETTER'),
        },
        {
          colorClass: 'green',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.BEST'),
        },
      ];
    }
    if (measurementType?.qualityType === MeasurementQualityType.MIDDLE_IS_BETTER) {
      return [
        {
          colorClass: 'red',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.TOO_HIGH'),
        },
        {
          colorClass: 'orange',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.HIGH'),
        },
        {
          colorClass: 'light-green',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.TARGET_VALUE'),
        },
        {
          colorClass: 'orange',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.LOW'),
        },
        {
          colorClass: 'red',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.TOO_LOW'),
        },
      ];
    }
    if (measurementType?.qualityType === MeasurementQualityType.HIGHER_IS_BETTER) {
      return [
        {
          colorClass: 'green',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.BEST'),
        },
        {
          colorClass: 'light-green',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.BETTER'),
        },
        {
          colorClass: 'olive',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.TARGET_VALUE'),
        },
        {
          colorClass: 'orange',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.LOW'),
        },
        {
          colorClass: 'red',
          label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.TOO_LOW'),
        },
      ];
    }
    return [];
  }

  loadCustomTypes(measurementTypeConfig: MeasurementTypeSelectionResponse) {
    this.customMeasurementTypes = [];
    const customMeasurementsExist = measurementTypeConfig.measurementGroups.findIndex(mg => mg.group === MeasurementCategory.CUSTOM_MEASUREMENTS);

    if(customMeasurementsExist !== -1) {
      measurementTypeConfig.measurementGroups.find(mg => mg.group === MeasurementCategory.CUSTOM_MEASUREMENTS).measurementTypes.forEach(mt => {
        this.customMeasurementTypes.push({
          measurementCategory: MeasurementCategory.CUSTOM_MEASUREMENTS,
          id: mt.measurementType,
          qualityType: MeasurementQualityType.MIDDLE_IS_BETTER,
          unit: mt.customUnit,
          icon: 'icn_custom_measurement.svg',
          allowNegativeValues: false,
          roundValueToDecimals: 0,
          formatValueStringFunction: null,
          customName: mt.customName
        });
      });
    }
    return this.customMeasurementTypes;
  }



  // getQualityColorOfDataPoint(measurementTypeId: string, quality: MeasurementLimitIndication): string {
  //   const measurementQualityType = this.getMeasurementQualityType(measurementTypeId);
  //   if (measurementQualityType === MeasurementQualityType.LOWER_IS_BETTER) {
  //     if (value <= 200) return COLOR_GREEN;
  //     if (value <= 400) return COLOR_LIGHT_GREEN;
  //     if (value <= 600) return COLOR_OLIVE;
  //     if (value <= 800) return COLOR_ORANGE;
  //     return COLOR_RED;
  //   } else if (measurementQualityType === MeasurementQualityType.MIDDLE_IS_BETTER) {
  //     if (value <= 200) return COLOR_RED;
  //     if (value <= 400) return COLOR_ORANGE;
  //     if (value <= 600) return COLOR_LIGHT_GREEN;
  //     if (value <= 800) return COLOR_ORANGE;
  //     return COLOR_RED;
  //   } else if (measurementQualityType === MeasurementQualityType.HIGHER_IS_BETTER) {
  //     if (value <= 200) return COLOR_RED;
  //     if (value <= 400) return COLOR_ORANGE;
  //     if (value <= 600) return COLOR_OLIVE;
  //     if (value <= 800) return COLOR_LIGHT_GREEN;
  //     return COLOR_GREEN;
  //   }
  //   return COLOR_RED;
  // }
  getAtalmedialLegendData() {
    return [
      {
        colorClass: 'orange',
        label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.DIVERGENT'),
      },
      {
        colorClass: 'green',
        label: this.translate.instant('MEASUREMENT_DETAILS.QUALITY.NORMAL'),
      }
    ]
  }

  getAtalmedialQualityColor(limitIndication: any) {
    if (limitIndication === MeasurementLimitIndication.BEST) return COLOR_GREEN;
    if (limitIndication === MeasurementLimitIndication.WORST) return COLOR_ORANGE;
    return '#000000';
  }

  getGradientLineColorsBasedOnIndicatorType(indicatorType: IndicatorType) {
    if(indicatorType === IndicatorType.BOTH) {
      return [
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
      ];
    } else if(indicatorType === IndicatorType.HIGH) {
      return [
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
      ];
    } else {
      return [
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
        {
          color: '#0B9645',
          // colorHumanString: 'dark green',
        },
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
        {
          color: '#F4B710',
          // colorHumanString: 'orange',
        },
      ];
    }
  }
}


// todo: Below are deprecated rng based functions
// todo: Below are deprecated rng based functions
// todo: Below are deprecated rng based functions
// todo: Below are deprecated rng based functions
// todo: Below are deprecated rng based functions

// getQualityByDataPointValue(value: any, measurementTypeId: string): string {
//   const measurementQualityType = this.getMeasurementQualityType(measurementTypeId);
//   if (measurementQualityType === MeasurementQualityType.LOWER_IS_BETTER) {
//     if (value <= 200) return MeasurementLimitIndication.BEST;
//     if (value <= 400) return MeasurementLimitIndication.BETTER;
//     if (value <= 600) return MeasurementLimitIndication.MIDDLE;
//     if (value <= 800) return MeasurementLimitIndication.WORSE;
//     return MeasurementLimitIndication.WORST;
//   } else if (measurementQualityType === MeasurementQualityType.MIDDLE_IS_BETTER) {
//     if (value <= 200) return MeasurementLimitIndication.WORST;
//     if (value <= 400) return MeasurementLimitIndication.WORSE;
//     if (value <= 600) return MeasurementLimitIndication.MIDDLE;
//     if (value <= 800) return MeasurementLimitIndication.WORSE;
//     return MeasurementLimitIndication.WORST;
//   } else if (measurementQualityType === MeasurementQualityType.HIGHER_IS_BETTER) {
//     if (value <= 200) return MeasurementLimitIndication.WORST;
//     if (value <= 400) return MeasurementLimitIndication.WORSE;
//     if (value <= 600) return MeasurementLimitIndication.MIDDLE;
//     if (value <= 800) return MeasurementLimitIndication.BETTER;
//     return MeasurementLimitIndication.BEST;
//   }
//   return COLOR_RED;
// }

/**
 * Generates a random set of measurement tiles
 * @param {boolean} sortAlphabetically
 * @returns {any}
 */
// generateRandomLatestMeasurements(sortAlphabetically = false): any {
//   const measurementCategories = [...MEASUREMENT_CATEGORIES];
//   const measurementTypes = {...MEASUREMENT_TYPES};
//   const PROGRESSION_TYPES = ['Rising', 'Declining', 'Equal'];
//   const LIMIT_INDICATIONS = {
//     HIGHER_IS_BETTER: ['BEST', 'BETTER', 'TARGET_VALUE', 'LOW', 'TOO_LOW'],
//     MIDDLE_IS_BETTER: ['TOO_HIGH', 'HIGH', 'TARGET_VALUE', 'LOW', 'TOO_LOW'],
//     LOWER_IS_BETTER: ['TOO_HIGH', 'HIGH', 'TARGET_VALUE', 'BETTER', 'BEST'],
//   };
//
//   const dashboardMeasurements: any = [];
//
//   for (let i = 0; i < measurementCategories.length; i++) {
//     const measurementCategory: any = measurementCategories[i];
//     const measurementCategoryObj = {
//       key: measurementCategory,
//       isMeasurementType: true,
//       order: i,
//       // @ts-ignore
//       tiles: [],
//     };
//     let counter = 0;
//     for (const j in measurementTypes) {
//       const measurementType = measurementTypes[j];
//       if (measurementType.id === 'CHOLESTEROL_B' || measurementType.id === 'BLOODSUGAR_B') continue;
//       const value = this.numberService.getRandomIntBetween(1, 1300);
//       if (measurementType.measurementCategory === measurementCategory) {
//         measurementCategoryObj.tiles.push({
//           measurementType: measurementType.id,
//           measurementTypeString: this.getMeasurementTitleString(measurementType.id),
//           value,
//           // valueString: this.measurementService.formatMeasurementValue(measurementType.id, value),
//           interpretation: 'normal',
//           order: counter,
//           progression: PROGRESSION_TYPES[this.numberService.getRandomIntBetween(0, PROGRESSION_TYPES.length - 1)],
//           limitIndication: LIMIT_INDICATIONS[measurementType.qualityType][this.numberService.getRandomIntBetween(0, LIMIT_INDICATIONS[measurementType.qualityType].length - 1)],
//         });
//         counter++;
//       }
//     }
//
//     dashboardMeasurements.push(measurementCategoryObj);
//   }
//
//   if (sortAlphabetically) {
//     for (const i in dashboardMeasurements) {
//       dashboardMeasurements[i].tiles = dashboardMeasurements[i].tiles.sort((a: any, b: any) => a.measurementTypeString > b.measurementTypeString);
//     }
//   }
//   // console.log('presort dashboardMeasurements', dashboardMeasurements);
//
//   return dashboardMeasurements;
// }
//
// getChartGradientData(measurementTypeId: string): any[] {
//   const measurementQualityType = this.getMeasurementQualityType(measurementTypeId);
//   if (measurementQualityType === MeasurementQualityType.LOWER_IS_BETTER) {
//     return [
//       { limit: 200, color: COLOR_RED },
//       { limit: 400, color: COLOR_ORANGE },
//       { limit: 600, color: COLOR_LIGHT_GREEN },
//       { limit: 400, color: COLOR_LIGHT_GREEN },
//       { limit: 200, color: COLOR_GREEN },
//     ];
//   } else if (measurementQualityType === MeasurementQualityType.MIDDLE_IS_BETTER) {
//     return [
//       { limit: 200, color: COLOR_RED },
//       { limit: 400, color: COLOR_ORANGE },
//       { limit: 600, color: COLOR_LIGHT_GREEN },
//       { limit: 800, color: COLOR_ORANGE },
//       { limit: 1000, color: COLOR_RED },
//     ];
//   } else if (measurementQualityType === MeasurementQualityType.HIGHER_IS_BETTER) {
//     return [
//       { limit: 200, color: COLOR_GREEN },
//       { limit: 400, color: COLOR_LIGHT_GREEN },
//       { limit: 600, color: COLOR_OLIVE },
//       { limit: 800, color: COLOR_ORANGE },
//       { limit: 1000, color: COLOR_RED },
//     ];
//   }
//   return [
//     { limit: 200, color: COLOR_RED },
//     { limit: 400, color: COLOR_ORANGE },
//     { limit: 600, color: COLOR_OLIVE },
//     { limit: 800, color: COLOR_LIGHT_GREEN },
//     { limit: 1000, color: COLOR_GREEN },
//   ];
// }
//
// aggregateChartDataPerDay(dataPoints: any, measurementTypeId: string): any[] {
//   const dataPointsAveragedPerDay: any = {};
//   for (let i = 0; i < dataPoints.length; i++) {
//     const dataPoint = dataPoints[i];
//     const dayString = `${dataPoint.x.getFullYear()}-${dataPoint.x.getMonth()}-${dataPoint.x.getDate()}`;
//     if (!dataPointsAveragedPerDay[dayString]) {
//       dataPointsAveragedPerDay[dayString] = [];
//     }
//     dataPointsAveragedPerDay[dayString].push(dataPoint);
//   }
//
//   const pointsAveragedPerDay = [];
//   for (const i in dataPointsAveragedPerDay) {
//     const pointsInDay = dataPointsAveragedPerDay[i];
//     if (pointsInDay.length) {
//       let sumValueInDay = 0;
//       for (const j in pointsInDay) {
//         const pointInDay = pointsInDay[j] ;
//         sumValueInDay += pointInDay.y;
//       }
//       const dataPointValue = Math.round(sumValueInDay / pointsInDay.length);
//       const dateDay = new Date(pointsInDay[0].x.getFullYear(), pointsInDay[0].x.getMonth(), pointsInDay[0].x.getDate(), 0, 0, 0);
//       pointsAveragedPerDay.push({
//         id: this.numberService.getRandomIntBetween(0, Number.MAX_SAFE_INTEGER),
//         y: dataPointValue,
//         x: dateDay,
//         quality: this.getQualityByDataPointValue(dataPointValue, measurementTypeId),
//         originalPointsDataPoints: pointsInDay,
//         startDate: dateDay,
//         endDate: null
//       });
//     }
//   }
//   return pointsAveragedPerDay;
// }
//
// aggregateChartDataPerWeek(dataPoints: any, measurementTypeId: string): any[] {
//   const dataPointsAveragedPerWeek: any = {};
//   for (let i = 0; i < dataPoints.length; i++) {
//     const dataPoint = dataPoints[i];
//     const weekData = this.dateService.getWeekNumber(dataPoint.x);
//     const weekStartYear = weekData.weekYear;
//     const weekNumber = weekData.weekNumber;
//     // console.log('dataPoint.x', dataPoint.x);
//     // console.log('weekNumber', weekNumber);
//     const weekString = `${weekStartYear}-${weekNumber}`;
//     if (!dataPointsAveragedPerWeek[weekString]) {
//       dataPointsAveragedPerWeek[weekString] = [];
//     }
//     // dataPoint.weekData = weekData;
//     dataPointsAveragedPerWeek[weekString].push(dataPoint);
//   }
//   // console.log('dataPointsAveragedPerWeek', dataPointsAveragedPerWeek);
//
//   const pointsAveragedPerWeek = [];
//   for (const i in dataPointsAveragedPerWeek) {
//     const pointsInWeek = dataPointsAveragedPerWeek[i];
//     if (pointsInWeek.length) {
//       let sumValueWeek = 0;
//       for (const j in pointsInWeek) {
//         const pointInDay = pointsInWeek[j] ;
//         sumValueWeek += pointInDay.y;
//       }
//       const dataPointValue = Math.round(sumValueWeek / pointsInWeek.length);
//       const mondayDayOfThisWeek = pointsInWeek[0].x.getDate() - (pointsInWeek[0].x.getDay() + 6) % 7;
//       const mondayDateOfThisWeek = new Date(pointsInWeek[0].x.getFullYear(), pointsInWeek[0].x.getMonth(), mondayDayOfThisWeek, 0, 0, 0);
//       const sundayDateOfThisWeek = new Date(mondayDateOfThisWeek);
//       sundayDateOfThisWeek.setDate(sundayDateOfThisWeek.getDate() + 6);
//       // console.log('mondayOfThisWeek', mondayDayOfThisWeek);
//       // console.log('pointsInWeek[0].x', pointsInWeek[0].x);
//       // console.log('mondayDateOfThisWeek', mondayDateOfThisWeek);
//       pointsAveragedPerWeek.push({
//         id: this.numberService.getRandomIntBetween(0, Number.MAX_SAFE_INTEGER),
//         y: dataPointValue,
//         x: mondayDateOfThisWeek,
//         quality: this.getQualityByDataPointValue(dataPointValue, measurementTypeId),
//         originalPointsDataPoints: pointsInWeek,
//         startDate: mondayDateOfThisWeek,
//         endDate: sundayDateOfThisWeek,
//       });
//     }
//   }
//
//   // console.log('pointsAveragedPerWeek', pointsAveragedPerWeek);
//
//   return pointsAveragedPerWeek;
// }
//
// aggregateChartDataPerMonth(dataPoints: any, measurementTypeId: string): any[] {
//   const dataPointsAveragedPerMonth: any = {};
//   for (let i = 0; i < dataPoints.length; i++) {
//     const dataPoint = dataPoints[i];
//     const monthString = `${dataPoint.x.getFullYear()}-${dataPoint.x.getMonth()}`;
//     if (!dataPointsAveragedPerMonth[monthString]) {
//       dataPointsAveragedPerMonth[monthString] = [];
//     }
//     dataPointsAveragedPerMonth[monthString].push(dataPoint);
//   }
//   // console.log('dataPointsAveragedPerMonth', dataPointsAveragedPerMonth);
//
//   const pointsAveragedPerMonth = [];
//   for (const i in dataPointsAveragedPerMonth) {
//     const pointsInMonth = dataPointsAveragedPerMonth[i];
//     if (pointsInMonth.length) {
//       let sumValueMonth = 0;
//       for (const j in pointsInMonth) {
//         const pointInDay = pointsInMonth[j] ;
//         sumValueMonth += pointInDay.y;
//       }
//       const dataPointValue = Math.round(sumValueMonth / pointsInMonth.length);
//       const startDate = new Date(pointsInMonth[0].x.getFullYear(), pointsInMonth[0].x.getMonth(), 1, 0, 0, 0);
//       pointsAveragedPerMonth.push({
//         id: this.numberService.getRandomIntBetween(0, Number.MAX_SAFE_INTEGER),
//         y: dataPointValue,
//         x: startDate,
//         quality: this.getQualityByDataPointValue(dataPointValue, measurementTypeId),
//         originalPointsDataPoints: pointsInMonth,
//         startDate: startDate,
//         endDate: this.dateService.getLastDayOfMonthAsDate(startDate),
//       });
//     }
//   }
//
//   // console.log('pointsAveragedPerMonth', pointsAveragedPerMonth);
//
//   return pointsAveragedPerMonth;
// }
//
// aggregateChartDataPerQuarter(dataPoints: any, measurementTypeId: string): any[] {
//   const dataPointsAveragedPerQuarter: any = {};
//   for (let i = 0; i < dataPoints.length; i++) {
//     const dataPoint = dataPoints[i];
//     const quarterString = `${dataPoint.x.getFullYear()}-q${this.dateService.getQuarter(dataPoint.x)}`;
//     if (!dataPointsAveragedPerQuarter[quarterString]) {
//       dataPointsAveragedPerQuarter[quarterString] = [];
//     }
//     dataPointsAveragedPerQuarter[quarterString].push(dataPoint);
//   }
//   // console.log('dataPointsAveragedPerQuarter', dataPointsAveragedPerQuarter);
//
//   const pointsAveragedPerQuarter = [];
//   for (const i in dataPointsAveragedPerQuarter) {
//     const pointsInQuarter = dataPointsAveragedPerQuarter[i];
//     if (pointsInQuarter.length) {
//       let sumValueQuarter = 0;
//       for (const j in pointsInQuarter) {
//         const pointInDay = pointsInQuarter[j] ;
//         sumValueQuarter += pointInDay.y;
//       }
//       const dataPointValue = Math.round(sumValueQuarter / pointsInQuarter.length);
//       const firstDayOfQuarter = this.dateService.getFirstDayOfQuarter(pointsInQuarter[0].x);
//       pointsAveragedPerQuarter.push({
//         id: this.numberService.getRandomIntBetween(0, Number.MAX_SAFE_INTEGER),
//         y: dataPointValue,
//         x: firstDayOfQuarter,
//         quality: this.getQualityByDataPointValue(dataPointValue, measurementTypeId),
//         originalPointsDataPoints: pointsInQuarter,
//         startDate: firstDayOfQuarter,
//         endDate: this.dateService.getLastDayOfQuarter(firstDayOfQuarter),
//       });
//     }
//   }
//   // console.log('pointsAveragedPerQuarter', pointsAveragedPerQuarter);
//
//   return pointsAveragedPerQuarter;
// }
//
// /** Todo deprecate soon when API implemented */
// getSumOfAddedByCategorieOfDataset(dataset: any): any {
//   const sums: any = {};
//   sums[MeasurementSource.HEALTHCARE_PROVIDER] = 0;
//   sums[MeasurementSource.MANUALLY] = 0;
//   sums[MeasurementSource.WEARABLE] = 0;
//   for (const i in dataset) {
//     sums[dataset[i].addedBy]++;
//   }
//   const returnSums = [];
//   // exclude categories with 0 fields
//   for (const i in sums) {
//     if (sums[i]) {
//       returnSums.push({ addedById: i, sum: sums[i] });
//     }
//   }
//   return returnSums;
// }


