import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'exercise-minutes-block',
  templateUrl: './exercise-minutes-block.component.html',
  styleUrls: ['./exercise-minutes-block.component.scss']
})
export class ExerciseMinutesBlockComponent implements OnChanges {
  @Input() exerciseMinutes: number = 0;
  @Input() exerciseRulesActivated: boolean = false;
  @Input() wearableConnected: boolean = false;
  progressPercentage: number = 0;
  walkingManPositionPercentage: number = 0;


  ngOnChanges(changes: SimpleChanges) {
    if(changes.exerciseMinutes && changes.exerciseMinutes.currentValue > 0) {
      this.progressPercentage = (changes.exerciseMinutes.currentValue / 60) * 100;
      this.walkingManPositionPercentage = (240 / 100) * this.progressPercentage;
      console.log(this.walkingManPositionPercentage);
    }
  }
}
