<div class="exercise-minutes-block-container is-first">
  <div class="disabled-overlay" *ngIf="wearableConnected === false || exerciseRulesActivated === false" [routerLink]="!wearableConnected && exerciseRulesActivated ? '/dashboard/settings/sources' : ''">
    <div class="exercise-minutes-warning">
      <span *ngIf="!wearableConnected">{{'DASHBOARD.CONNECT_WEARABLE_FITCOINS' | translate}}</span>
      <span *ngIf="!exerciseRulesActivated">{{'DASHBOARD.EXERCISE_RULES_NOT_ACTIVATED' | translate}}</span>
    </div>
  </div>
  <div class="green-background">
    <div class="progress-container">
      <div class="walking-man-container">
        <div class="walking-man zero" [ngStyle]="{'transform': 'translateX(' + walkingManPositionPercentage + 'px)'}" *ngIf="exerciseMinutes >= 0 && exerciseMinutes < 10"></div>
        <div class="walking-man ten" [ngStyle]="{'transform': 'translateX(' + walkingManPositionPercentage + 'px)'}" *ngIf="exerciseMinutes >= 10 && exerciseMinutes < 20"></div>
        <div class="walking-man twenty" [ngStyle]="{'transform': 'translateX(' + walkingManPositionPercentage + 'px)'}" *ngIf="exerciseMinutes >= 20 && exerciseMinutes < 30"></div>
        <div class="walking-man thirty" [ngStyle]="{'transform': 'translateX(' + walkingManPositionPercentage + 'px)'}" *ngIf="exerciseMinutes >= 30 && exerciseMinutes < 40"></div>
        <div class="walking-man forty" [ngStyle]="{'transform': 'translateX(' + walkingManPositionPercentage + 'px)'}" *ngIf="exerciseMinutes >= 40 && exerciseMinutes < 50"></div>
        <div class="walking-man fifty" [ngStyle]="{'transform': 'translateX(' + walkingManPositionPercentage + 'px)'}" *ngIf="exerciseMinutes >= 50 && exerciseMinutes < 60"></div>
        <div class="walking-man sixty" [ngStyle]="{'transform': 'translateX(' + walkingManPositionPercentage + 'px)'}" *ngIf="exerciseMinutes >= 60"></div>
        <div class="finish-flag" [ngStyle]="{'background-image': exerciseMinutes >= 60 ?
                                                                 'url(\'/assets/img/dashboard/exercise-minutes/flag_green.svg\')' :
                                                                 'url(\'/assets/img/dashboard/exercise-minutes/flag_transparent.svg\')'}"></div>
      </div>
      <div class="progress-line-container">
        <div class="fitcoin-logo" *ngIf="exerciseMinutes < 10"></div>
        <div class="fitcoin-logo second" *ngIf="exerciseMinutes < 20"></div>
        <div class="fitcoin-logo third" *ngIf="exerciseMinutes < 30"></div>
        <div class="fitcoin-logo fourth" *ngIf="exerciseMinutes < 40"></div>
        <div class="fitcoin-logo fifth" *ngIf="exerciseMinutes < 50"></div>
        <div class="fitcoin-logo sixth" *ngIf="exerciseMinutes < 60"></div>
        <div class="progress-line">
          <div class="progress-line-progressed" [ngStyle]="{width: progressPercentage + '%'}"></div>
        </div>
        <div class="progress-block-wrapper">
          <div class="progress-block"></div>
          <div class="progress-block"></div>
          <div class="progress-block"></div>
          <div class="progress-block"></div>
          <div class="progress-block"></div>
          <div class="progress-block"></div>
        </div>
      </div>
      <div class="progress-numbers-wrapper">
        <div class="progress-number zero">0</div>
        <div class="progress-number"></div>
        <div class="progress-number twenty">20</div>
        <div class="progress-number forty">40</div>
        <div class="progress-number"></div>
        <div class="progress-number sixty">60</div>
      </div>
    </div>
  </div>
</div>
