<p class="invitation-greeting" *ngIf="invitationType === Invitation.DEFAULT"><span
  class="greeting-name">{{ ' ' + ('INVITATION.WELCOME_TO' | translate) }}</span></p>
<p class="invitation-greeting" *ngIf="invitationType === Invitation.REWARDS"><span
  class="greeting-name">{{ ' ' + ('INVITATION.WELCOME_TO_REWARDS' | translate) }}</span></p>
<p class="invitation-greeting" *ngIf="invitationType === Invitation.HEALTH || invitationType === Invitation.CAREGIVER">
  <span class="greeting-name">{{ ' ' + ('INVITATION.WELCOME_TO_HEALTH' | translate) }}</span></p>
<p class="invitation-greeting" *ngIf="invitationType === Invitation.RESEARCH"><span
  class="greeting-name">{{ ' ' + ('INVITATION.WELCOME_TO_RESEARCH' | translate : {researchName: researchName}) }}</span>
<p class="invitation-greeting" *ngIf="invitationType === Invitation.CHALLENGE"><span
  class="greeting-name">{{ ' ' + ('INVITATION.WELCOME_TO_CHALLENGE' | translate : {customerName: customerName}) }}</span>
<p class="invitation-greeting" *ngIf="invitationType === Invitation.COMMUNITY"><span
    class="greeting-name">{{ ' ' + ('INVITATION.WELCOME_TO_COMMUNITY' | translate : {communityName: communityName, customerName: customerName}) }}</span>
</p>
<p class="invitation-greeting" *ngIf="invitationType === Invitation.CONNECTION"><span
  class="greeting-name">{{ ' ' + ('INVITATION.WELCOME_TO_CONNECTION' | translate) }}</span>
</p>

<img class="invitation-company-logo" *ngIf="companyLogoSrc" [src]="companyLogoSrc" alt="Company logo">
<img *ngIf="invitationType === Invitation.CHALLENGE" src="assets/img/invitation/img_challenge@3x.png" alt="Challenge"
     class="invitation-main-image">
<img *ngIf="invitationType === Invitation.COMMUNITY" src="assets/img/invitation/img_challenge@3x.png" alt="Community"
     class="invitation-main-image" />
<img *ngIf="invitationType === Invitation.RESEARCH" src="assets/img/invitation/img_research@3x.png" alt="Research"
     class="invitation-main-image">
<img *ngIf="invitationType === Invitation.REWARDS" src="assets/img/invitation/img_rewards.png" alt="Rewards"
     class="invitation-main-image">
<img *ngIf="invitationType === Invitation.HEALTH" src="assets/img/invitation/img_monitoring.png" alt="Health"
     class="invitation-main-image">
<img *ngIf="invitationType === Invitation.CAREGIVER" src="assets/img/invitation/img_caregiver.png" alt="Caregiver"
     class="invitation-main-image">
<img *ngIf="invitationType === Invitation.CONNECTION" src="assets/img/invitation/img_connection.png" alt="Caregiver"
     class="invitation-main-image">

<ng-container></ng-container>
<p class="invitation-instructions" *ngIf="invitationType === Invitation.REWARDS"
   [innerHTML]="'INVITATION.REWARDS_WELCOME' | translate"></p>
<p class="invitation-instructions"
   *ngIf="invitationType === Invitation.CHALLENGE">{{ ('INVITATION.CHALLENGE_WELCOME' | translate: {customerName: customerName})}}
</p>
<p class="invitation-instructions"
   *ngIf="invitationType === Invitation.COMMUNITY">{{ ('INVITATION.COMMUNITY_WELCOME' | translate: {communityName: communityName, customerName: customerName})}}
</p>
<p class="invitation-instructions"
   *ngIf="invitationType === Invitation.RESEARCH">{{ ('INVITATION.RESEARCH_WELCOME' | translate: {researchName: researchName})}}
<p>
<p class="invitation-instructions"
   *ngIf="invitationType === Invitation.HEALTH"
   [innerHTML]="'INVITATION.HEALTH_WELCOME' | translate: {caregiverName: caregiverName}">
</p>
<p class="invitation-instructions"
   *ngIf="invitationType === Invitation.CONNECTION"
   [innerHTML]="'INVITATION.CONNECTION_WELCOME' | translate">
</p>
<p class="invitation-instructions"
   *ngIf="invitationType === Invitation.CAREGIVER" [innerHTML]="'INVITATION.CAREGIVER_WELCOME' | translate"></p>
<p class="invitation-instructions" *ngIf="invitationType === Invitation.DEFAULT">
  {{ 'INVITATION.DEFAULT_WELCOME' | translate }}</p>
<p class="invitation-instructions" *ngIf="!loggedIn">{{ 'INVITATION.NO_ACCOUNT' | translate }}</p>

<ng-container *ngIf="!loggedIn">
  <app-button
    [buttonText]="'ONBOARDING.GET_STARTED_BUTTON' | translate"
    [styles]="[BUTTON_STYLES.shaped, BUTTON_STYLES.secondaryAlternative]"
    routerLink="/register"
    class="get-started-button"></app-button>
  <p class="invitation-instructions">{{ 'INVITATION.EXISTING_ACCOUNT' | translate }}</p>
  <app-button
    [buttonText]="'SHARED.LOGIN' | translate"
    [styles]="[BUTTON_STYLES.shaped, BUTTON_STYLES.dark]"
    routerLink="/login">
  </app-button>
</ng-container>

<ng-container *ngIf="loggedIn">
  <app-button
    [buttonText]="'SHARED.PARTICIPATE' | translate"
    [styles]="[BUTTON_STYLES.shaped, BUTTON_STYLES.dark]"
    routerLink="/login">
  </app-button>
</ng-container>
