import {Component, Input, OnInit} from '@angular/core';
import Invitation from '../../models/invitation/Invitation';
import {BUTTON_STYLES} from '../button/button.component';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../api/api.service';
import {OidcService} from "../../../oidc/oidc.service";

@Component({
  selector: 'invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  @Input() invitationType: Invitation = Invitation.DEFAULT;
  @Input() customerName: string;
  @Input() companyLogoSrc: string;

  Invitation = Invitation;
  BUTTON_STYLES = BUTTON_STYLES;
  loggedIn = false;
  researchName: string;
  caregiverName: string;
  communityName: string;

  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              private oidService: OidcService
              ) { }

  async ngOnInit(): Promise<void> {
    if(this.oidService.isAuthenticated()) {
      this.loggedIn = true;
    }

    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.customerName = params['customerName'];
      this.researchName = params['researchName'];
      this.caregiverName = params['careGiverName'];
      this.communityName = params['communityName'];

      const contactId = params['contactId'];
      const researchId = params['researchInviteToken'];
      const careGiverName = params['careGiverName'];
      const careGiverToken = params['caregiverInviteToken'];
      const challengeInviteToken = params['challengeInviteToken'];
      const communityInviteToken = params['communityInviteToken'];
      const rewardsInviteToken = params['rewardsInviteToken'];

      if(challengeInviteToken) {
        localStorage.setItem('invitationChallengeToken', challengeInviteToken);
        this.invitationType = Invitation.CHALLENGE;
      }

      if(communityInviteToken) {
        localStorage.setItem('invitationCommunityToken', communityInviteToken);
        this.invitationType = Invitation.COMMUNITY;
      }

      if(careGiverName) {
        localStorage.setItem('careGiverName', careGiverName);
        this.invitationType = Invitation.HEALTH;
      }

      if(careGiverToken) {
        localStorage.setItem('careGiverToken', careGiverToken);
        this.invitationType = Invitation.CAREGIVER;
      }

      if (researchId) {
        localStorage.setItem('invitationResearchId', researchId);
        this.invitationType = Invitation.RESEARCH;
      }

      if (contactId) {
        localStorage.setItem('invitationContactId', contactId);
        localStorage.setItem('invitationHash', params['hash']);
        this.invitationType = Invitation.CONNECTION;
      }

      if(rewardsInviteToken) {
        localStorage.setItem('invitationRewardsToken', rewardsInviteToken);
        this.invitationType = Invitation.REWARDS;
      }
    });
  }
}
