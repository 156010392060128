import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { WearableVendorItemComponent } from '../../../../shared/components/devices-apps-item/wearable-vendor-item.component';
import { Supplier } from '../../../../shared/models/settings/supplierResponse';
import { environment } from '../../../../../environments/environment';
import { NativeAppService } from "../../../../shared/api/native-app.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ApiService } from "../../../../shared/api/api.service";
import { SupplierIconService } from "../../../../shared/utils/supplier-icon.service"
export interface DeviceAppsItem {
  iconSrc: string;
  title: string;
}

@Component({
  selector: 'devices-apps',
  templateUrl: './devices-apps.component.html',
  styleUrls: ['./devices-apps.component.scss']
})
export class DevicesAppsComponent implements OnInit, AfterViewInit {
  @Input() suppliers: Supplier[];

  @ViewChildren(WearableVendorItemComponent) devicesAppsItemsComponent: QueryList<WearableVendorItemComponent>;

  form: UntypedFormGroup;

  isIOS = false;

  conflictingSuppliers = false;

  constructor(
    readonly controlContainer: ControlContainer,
    readonly nativeAppService: NativeAppService,
    readonly modal: NgxSmartModalService,
    readonly apiService: ApiService,
    readonly supplierIconService: SupplierIconService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.form = <UntypedFormGroup>this.controlContainer.control;
    this.suppliers = this.suppliers.sort((a, b) => a['displayName'].toLowerCase() > b['displayName'].toLowerCase() ? 1 : a['displayName'].toLowerCase() === b['displayName'].toLowerCase() ? 0 : -1);

    this.isIOS = this.nativeAppService.isIOS();

    const conflictingMeasurementTypes = await this.apiService.getConflictingMeasurementTypes();
    if(Object.keys(conflictingMeasurementTypes).length > 0) {
      this.conflictingSuppliers = true;
    }
 }

  ngAfterViewInit(): void {
  }

  selectItem(isSelected: boolean, supplierKey: string) {
    this.devicesAppsItemsComponent.forEach(item => item.isSelected = false);
    this.devicesAppsItemsComponent.find(item => item.key === supplierKey).isSelected = isSelected;
  }

  get isEnabledAndSelected() {
    return this.devicesAppsItemsComponent?.find(item => item.isSelected && item.isEnabled);
  }

  get isSelected() {
    return !!this.devicesAppsItemsComponent?.find(item => item.isSelected);
  }

  get selectedItem() {
    return this.devicesAppsItemsComponent?.find(item => item.isSelected);
  }

  openMeasurementTypesModal(): any {
    this.modal.open('mTypeSourceModal');
  }
}
