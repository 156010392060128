import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { OidcService } from '../../oidc/oidc.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/api/api.service';
import { ApiBadgeService } from '../../shared/api/api-badge.service';
import { MeasurementHubService } from '../../shared/api/hubs/measurement-hub.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiRegistrationService } from '../../shared/api/api.registration.service';
import { ConsentTypes } from '../../shared/models/consentTypes';
import { ApiIdentityService } from '../../shared/api/api-identity.service';
import { Store } from '@ngrx/store';
import UserProfile from '../../shared/models/userProfile';
import { setCulture, setProfile, setUserId } from '../../store/userProfile/userProfile.actions';
import { NativeAppService } from '../../shared/api/native-app.service';
import { ApiHealthService } from '../../shared/api/api-health.service';
import { HamburgerMenuComponent } from '../../shared/components/dashboard/hamburger-menu/hamburger-menu.component';
import { ApiRewardsService } from '../../shared/api/api-rewards.service';
import { LocalStorageService } from '../../shared/utils/local-storage.service';

@Component({
  selector: 'dashboard',
  templateUrl: './base.component.html',
  styleUrls: [ './base.component.scss' ],
})
export class BaseComponent implements OnDestroy, AfterViewInit {
  @HostBinding('attr.id') attrId = 'dashboard-host-component';
  @ViewChild(HamburgerMenuComponent) hamburgerMenuComponent: HamburgerMenuComponent;

  hamburgerMenuOpen = false;
  hamburgerToggleButtonId = 'toggle-hamburger-button';
  hamburgerIconShown = true;
  connectionLostSupplier: string = null;

  private _unsubscribe$ = new Subject<boolean>();

  ConsentTypes = ConsentTypes;

  constructor(private oidcService: OidcService,
              private oauthService: OAuthService,
              private apiService: ApiService,
              private apiRegistrationService: ApiRegistrationService,
              private router: Router,
              private renderer: Renderer2,
              private elRef: ElementRef,
              private apiBadgeService: ApiBadgeService,
              private measurementHubService: MeasurementHubService,
              private modal: NgxSmartModalService,
              private nativeAppService: NativeAppService,
              private store: Store<{ 'userProfile': UserProfile }>,
              private apiIdentityService: ApiIdentityService,
              private apiHealthService: ApiHealthService,
              private localStorage: LocalStorageService,
              private apiRewardsService: ApiRewardsService) {
    this.oauthService.loadDiscoveryDocument().then(async () => {
      await this.initNativeAppStorage();
      this.oidcService.setupAutomaticSilentRefresh();

      await this.apiRegistrationService.getCultureCode();
      const cultureCode = await this.apiRegistrationService.getCultureCode();
      this.store.dispatch(setCulture({ cultureCode: cultureCode }));

      this.apiBadgeService.startBadgeListeners();
      this.initConnectionLostListener();
      this.finalizeInvitationFlow();
    });
  }

  async initNativeAppStorage(): Promise<void> {
    const isNativeApp = this.nativeAppService.nativeAppAvailable();

    if (isNativeApp) {
      const userInfo = this.oauthService.getIdentityClaims();
      if (userInfo) {
        this.store.dispatch(setUserId({ userId: userInfo['sub'] }));

        this.apiService.getFullUserProfile().then(profile => {
          this.store.dispatch(setProfile({ userProfile: profile }));
        });
      }
    } else {
      return new Promise(resolve => resolve());
    }
  }

  ngAfterViewInit() {
    this.apiIdentityService.getConsents(ConsentTypes.TermsAndConditions).then((termsAndConditionsConsent) => {
      if (!termsAndConditionsConsent || !termsAndConditionsConsent.accepted) {
        this.modal.open('consentUpdateModal');
      }
    });
  }

  toggleHamburgerMenu(): void {
    this.hamburgerMenuOpen = !this.hamburgerMenuOpen;
  }

  get hasNativeMenu() {
    if (this.nativeAppService.hasAndroidBridge('NativeApp2')) {
      return false;
    }
    if (this.nativeAppService.nativeAppAvailable()) {
      return true;
    }
    this.renderer.setStyle(this.elRef.nativeElement, 'padding-bottom', '0');
    return false;
  }

  initConnectionLostListener() {
    this.measurementHubService.init();
    this.measurementHubService.supplierDisconnected$.pipe(takeUntil(this._unsubscribe$)).subscribe(supplierName => {
      this.connectionLostSupplier = supplierName;
      if (supplierName) {
        this.modal.open('connectionLostPopup');
      }
    });
  }

  finalizeInvitationFlow(): void {
    const invitationContactId = this.localStorage.getItem('invitationContactId');
    const invitationResearchId = this.localStorage.getItem('invitationResearchId');
    const invitationCareGiverName = this.localStorage.getItem('careGiverName');
    const invitationCareGiverToken = this.localStorage.getItem('careGiverToken');
    const invitationChallengeToken = this.localStorage.getItem('invitationChallengeToken');
    const invitationCommunityToken = this.localStorage.getItem('invitationCommunityToken');
    const rewardsInviteToken = localStorage.getItem('invitationRewardsToken');

    // TODO: add external IDs to Company and Users
    // CompanyId guid, AspNet_UserId guid, Domain (Research|Health|Challenge|Contact)

    // TODO are these refreshToken calls even needed? seems we already cover our needs with this.initNativeAppStorage()
    //  refreshToken purpose: change the user's subscription without having to re-login
    switch (true) {
      case !!invitationResearchId:
        this.oidcService.finalizeInvitationResearchFlow(invitationResearchId).then();
        break;

      case !!invitationContactId:
        this.oidcService.finalizeInvitationContactFlow().then();
        break;

      case !!invitationChallengeToken:
        this.apiService.linkUserToChallenge(invitationChallengeToken).then(() => {
          localStorage.removeItem('invitationChallengeToken');
          // TODO fix refreshToken on native err: 400 'invalid_grant'
          this.oauthService.refreshToken()
            // .then(() => this.router.navigateByUrl('dashboard/games/challenge'))
            // TODO after refreshToken on native err is fixed: replace `finally` w. `then`
            .finally(() => this.router.navigateByUrl('dashboard/games/challenge'));
        });
        break;

        case !!invitationCommunityToken:
        this.apiService.linkUserToChallenge(invitationCommunityToken).then(() => {
          localStorage.removeItem('invitationCommunityToken');
          this.oauthService.refreshToken()
            .finally(() => this.router.navigateByUrl('dashboard/network/communities'));
        });
        break;

      case !!invitationCareGiverToken:
        this.apiHealthService.connectUserToCaregiver(invitationCareGiverToken).then(() => {
          this.oauthService.refreshToken()
            // .then(() => {
            // TODO after refreshToken on native err is fixed: replace `finally` w. `then`
            .finally(() => {
              localStorage.removeItem('careGiverToken');
              this.hamburgerMenuComponent.ngOnInit().then();
            });
        });
        break;

      case !!invitationCareGiverName:
        localStorage.removeItem('careGiverName');
        this.oauthService.refreshToken()
          // .then(() => this.router.navigateByUrl('dashboard/settings/health'))
          // TODO after refreshToken on native err is fixed: replace `finally` w. `then`
          .finally(() => this.router.navigateByUrl('dashboard/settings/health'));
        break;

      case !!rewardsInviteToken:
        this.apiService.linkUserToRewards(rewardsInviteToken).then(() => {
          localStorage.removeItem('invitationRewardsToken');
          this.oauthService.refreshToken()
          // .then(() => this.router.navigateByUrl('dashboard/settings/health'))
          // TODO after refreshToken on native err is fixed: replace `finally` w. `then`
          .finally(() => this.router.navigateByUrl('dashboard/games/rewards'));
        });
        break;

      default:
        console.log('finalizeInvitationFlow', 'no invitation type matched');
        break;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }
}
