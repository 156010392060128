enum Invitation {
  CHALLENGE,
  RESEARCH,
  DEFAULT,
  REWARDS,
  HEALTH,
  CAREGIVER,
  CONNECTION,
  COMMUNITY
}

export default Invitation;
